import { compareTwoStrings, findBestMatch } from 'string-similarity';

export default {
  methods: {
    timeFuture(date, time) {
      return (!date || !time) || new Date(`${date}T${time}:00`) >= new Date
    },
    doDateRangesOverlap(range1, range2) {
      const start1 = new Date(range1[0])
      const end1 = new Date(range1[range1.length - 1])
      let start2 = new Date(range2[0])
      let end2 = new Date(range2[range2.length - 1])
      if (start2 > end2) {
        [start2, end2] = [end2, start2]
      }
      return start1 <= end2 && end1 >= start2
    },
    isOneOfDaysInRange(days, range) {
      const start = new Date(range[0])
      const end = new Date(range[range.length - 1])
      if (days.length) {
        if (Math.abs(end - start) >= 604800000) {
          return true
        } else {
          const startDay = start.getDay()
          const endDay = end.getDay()
          for (const day of days) {
            if (startDay <= day && endDay >= day ||
              endDay < startDay && day >= startDay ||
              day <= endDay && startDay > endDay) {
              return true
            }
          }
          return false
        }
      } else {
        return false
      }
    },
    getToday() {
      return new Date().toISOString().slice(0, 10)
    },
    formatDate(date) {
      if (date != null) {
        if ((date.match(/-/g) || []).length != 2) {
          return date
        }
        else {
          var splitDate = date.split("-")
          var formatted = `${splitDate[2]}.${splitDate[1]}.${splitDate[0]}`
          return formatted
        }
      }
      else {
        return ''
      }
    },
    formatDateBack(date) {
      if (date != null) {
        var splitDate = date.split(".")
        var formatted = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
        return formatted
      }
      else {
        return ''
      }
    },
    formatDateRange(dateRange) {
      if (dateRange != null && dateRange.length > 0) {
        return `${this.formatDate(dateRange[0])} - ${this.formatDate(dateRange[1])}`
      }
      else {
        return ''
      }
    },
    addTime(startTime, duration) {
      if (startTime !== undefined && duration !== undefined) {
        const startTimeSplit = startTime.split(':')
        let time = new Date(2000, 0, 1, startTimeSplit[0], startTimeSplit[1])
        let newDate = new Date(time.getTime() + duration * 60000);
        const hours = newDate.getHours()
        const minutes = newDate.getMinutes()
        return `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}`;
      }
      else {
        return ''
      }
    },
    getWeekDay(date) {
      let dateObject = new Date(date)
      return this.$store.state.weekDaysObject[dateObject.getDay()]
    },
    getImgSrc(filename) {
      return `/static/${filename}`;
    },
    findRegionNameByCode(code) {
      const region = this.findRegionBy('code', code);
      
      return region && region.name;
    },
    stripText(text, amount) {
      if (text && text.length > amount) {
        return text.substring(0, amount) + ' ...'
      }
      else {
        return text
      }
    },
    parseWeekdays(weekdays) {
      let stringWeekdays = "0000000"
      let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
      for (var i = 0; i < 7; i++) {
        if (weekdays.includes(days[i])) {
          stringWeekdays = stringWeekdays.substr(0, i) + '1' + stringWeekdays.substr(i + 1)
        }
      }
      return stringWeekdays
    },
    parseWeekdaysBack(stringWeekdays) {
      let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
      let weekdays = []
      for (var i = 0; i < 7; i++) {
        if (stringWeekdays[i] == '1') {
          weekdays.push(days[i])
        }
      }
      return weekdays
    },
    parseFilteredDays() {
      let weekdays = ['0', '0', '0', '0', '0', '0', '0']
      this.$store.state.filteredDays.forEach(element => {
        weekdays[element == '0' ? 6 : parseInt(element) - 1] = '1'
      })
      return weekdays.join('')
    },
    addBreakLines(text) {
      return text.replace(/\n/g, '<br>')
    },
    compareTwoStrings(strA, strB) {
      return compareTwoStrings(strA, strB);
    },
    findBestMatch(str, strs) {
      return findBestMatch(str, strs);
    },
    getDayNames(locale = 'en', format = 'long') {
      const formatter = new Intl.DateTimeFormat(locale, { weekday: format, timeZone: 'UTC' });
      const days = [1, 2, 3, 4, 5, 6, 7].map(day => {
        const dd = day < 10 ? `0${day}` : day;
        return new Date(`2017-01-${dd}T00:00:00+00:00`);
      });
      return days.map(date => formatter.format(date));
    },
    findRegionBy(prop, value) {
      if(!prop || !value){
        return undefined;
      }

      const regions = this.$store.state.regions;
      const key = Object.keys(regions).find(r => regions[r] && regions[r][prop] == value);

      return (key && ({
        name: key, 
        region: regions[key]
      })) || undefined;
    }
  }
}